import React from 'react'
import { graphql } from 'gatsby'
import BlockHeroLocations from 'components/blocks/BlockHeroLocations'
import BlockLocations from 'components/blocks/BlockLocations'
import BlockSpecs from 'components/blocks/BlockSpecs'
import BlockCompare from 'components/blocks/BlockCompare'
import BlockLogoGrid from 'components/blocks/BlockLogoGrid'
import BlockContact from 'components/blocks/BlockContact'
import Footer from 'components/Footer'
import Seo from 'components/Seo'

// markup
const Page = ({ data }) => {
  const cities = data.allPrismicCity.nodes
  const locations = data.allPrismicLocation.nodes
  const logos = data.allPrismicLogos.nodes[0].data.logos
  const content = {
    blockSpecs: {
      services: [
        {
          title: '空间',
          details: ['储藏空间', '休息区', '清洁间', '监控室', '配电间'],
        },
        {
          title: '服务',
          details: [
            '场地清洁卫生',
            '消防安全培训',
            '7/24管家服务',
            '物业关系维护',
          ],
        },
        {
          title: '设备',
          details: [
            '智能水电表',
            '智能门锁',
            'CCTV',
            '三星水槽',
            '油水分离器',
            '消防喷淋',
            '干粉灭火器及灭火毯',
            '排烟罩',
          ],
        },
        {
          title: '公共设施',
          details: ['水、电、煤气', 'WIFI', '空调', '垃圾临时存放间'],
        },
      ],
      buttonAddClass: 'reserve_equip',
    },
    logos: logos.map((logo) => {
      return {
        gatsbyImage: logo.logo,
        link: logo.link.text,
      }
    }),
  }
  const seoContent = {
    title: `店铺地图`,
  }
  return (
    <div>
      <Seo content={seoContent} />
      <BlockHeroLocations />
      <BlockLocations
        cities={cities}
        locations={locations}
        addClassPrefix='map_'
      />
      <BlockSpecs content={content.blockSpecs} />
      <BlockCompare />
      <BlockLogoGrid logos={content.logos} />
      <BlockContact buttonAddClass='reserve_bottom' />
      <Footer />
    </div>
  )
}

export default Page

export const query = graphql`
  query {
    allPrismicCity(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          prefix {
            text
          }
        }
      }
    }
    allPrismicLocation(sort: { order: DESC, fields: last_publication_date }) {
      nodes {
        uid
        data {
          title {
            text
          }
          excerpt {
            text
          }
          image {
            gatsbyImageData
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          city {
            uid
          }
        }
      }
    }
    allPrismicLogos {
      nodes {
        data {
          logos {
            logo {
              gatsbyImageData
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            link {
              text
            }
          }
        }
      }
    }
  }
`
