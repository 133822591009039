import React from 'react'
import Inview from 'components/Inview'
import ImageWrap from 'components/ImageWrap'
import LinkWrap from 'components/LinkWrap'

const Component = ({ locations, filterCitySlug = null }) => {
  if (filterCitySlug) {
    locations = locations.filter(
      (location) => location.city && location.city.slug === filterCitySlug
    )
  }
  return (
    <Inview className='grid gap-x-6 gap-y-12 md:grid-cols-12'>
      {locations.map((location, i) => {
        const addClass = location.addClassPrefix
          ? `${location.addClassPrefix}${i + 1}`
          : ''
        return (
          <LinkWrap
            key={i}
            to={`/locations/${location.slug}/`}
            className={`
          location ${addClass} 
          md:col-span-6 lg:col-span-4 fade-in-up 
        stagger-${i * 200}
      `}
          >
            <div className='image'>
              <ImageWrap image={location.gatsbyImage} />
            </div>
            <div className='text mt-4'>
              {location.city && (
                <h6 className='h6 color--grey-4'>{location.city.title}</h6>
              )}
              <h4 className='h5 mt-2 color--grey-6'>{location.title}</h4>
              <p className='mt-3 color--grey-5'>{location.excerpt}</p>
            </div>
          </LinkWrap>
        )
      })}
    </Inview>
  )
}

export default Component
