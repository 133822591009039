import React from 'react'
import ButtonContact from 'components/ButtonContact'
import BodyWrap from 'components/BodyWrap'
import Inview from 'components/Inview'

const Component = ({ content, isInner = false }) => {
  const { services, buttonAddClass } = content
  let disclaimerText = `*不同门店的厨房空间及配套设施有所差异，具体以门店实际情况为准。`
  if (isInner) {
    disclaimerText = `*具体以门店实际情况为准。`
  }
  return (
    <section className='block-specs bg--primary color--white'>
      <div className='container pt-20 md:pt-24 lg:pt-40 pb-20 md:pb-24 lg:pb-40'>
        {/* Text */}
        <Inview>
          <div className='eyebrow p5 line mb-10 lg:mb-0 fade-in-up'>
            厨房空间
          </div>
        </Inview>
        <Inview>
          <div className='grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 mt-16'>
            <div className='col-span-4 md:col-span-8 lg:col-span-6'>
              <h2 className='h2 fade-in-up mb-2 md:mb-0'>
                一应俱全， <br />
                满足您所有需求
              </h2>
              <Inview>
                <p className='smaller mt-12 hidden lg:block fade-in-up'>
                  {disclaimerText}
                </p>
              </Inview>
            </div>
            <div className='col-span-4 md:col-span-8 lg:col-span-5 mt-6 md:mt-12 lg:mt-2'>
              <p className='fade-in-up'>
                星选闪电场地的设计理念源于目前市场上不同品类的厨房
                <span className='no-wrap'>特性，</span>
                <span className='no-wrap'>设施</span>
                条件可以满足不同外卖品类的需求。
              </p>
              <p className='fade-in-up'>
                当然，我们的基础设施也可以针对您的需求做定制化调整。
                <span className='no-wrap'>来聊聊吧。</span>
              </p>
              <div className='fade-in-up mt-10'>
                <ButtonContact addClass={buttonAddClass} />
              </div>
              <Inview>
                <p className='smaller mt-12 lg:hidden fade-in-up'>
                  {disclaimerText}
                </p>
              </Inview>
            </div>
          </div>
        </Inview>
        {/* Accordion */}
        <Accordion services={services} />
        {/* Columns */}
        <Inview>
          <div className='grid grid-cols-4 md:grid-cols-12 mt-20 lg:mt-24 hidden md:grid'>
            {services.map((item, i) => (
              <Column key={i} i={i} item={item} />
            ))}
          </div>
        </Inview>
      </div>
    </section>
  )
}

export default Component

const Column = ({ item }) => {
  return (
    <div className='md:col-span-6 lg:col-span-3 fade-in-up stagger-300 md:pr-8'>
      <h3 className='h5'>{item.title}</h3>
      <hr className='mt-3 mb-5' />
      <List item={item} />
    </div>
  )
}

const Accordion = ({ services }) => {
  return (
    <div className='grid grid-cols-4 mt-12 md:hidden'>
      {services.map((item, i) => (
        <AccordionItem key={i} item={item} />
      ))}
    </div>
  )
}

class AccordionItem extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle() {
    this.setState((prevState) => {
      return {
        open: !prevState.open,
      }
    })
  }

  render() {
    const { item } = this.props
    return (
      <div className='col-span-4'>
        <Inview>
          <div className='fade-in-up'>
            <div
              className={`flex justify-between accordion--trigger ${
                this.state.open ? 'is-active' : ''
              }`}
              data-id='space'
              onClick={this.toggle}
            >
              <p className='h5 mb-0'>{item.title}</p>
              <div className='ml-auto'>
                <svg
                  width='26'
                  height='26'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <rect
                    x='.5'
                    y='.5'
                    width='25'
                    height='25'
                    rx='12.5'
                    stroke='#B5B3AB'
                  ></rect>
                  <path
                    d='M16.664 12.168h-3.168V9h-1.344v3.168H9v1.344h3.152v3.152h1.344v-3.152h3.168v-1.344z'
                    fill='#B5B3AB'
                  ></path>
                </svg>
              </div>
            </div>
            <div
              className={`accordion
            ${this.state.open ? 'is-active' : ''}
            `}
              data-id='space'
            >
              <div className='pt-6 pb-4 pr-8'>
                <List item={item} />
              </div>
            </div>
            <hr className='mt-6 mb-8' />
          </div>
        </Inview>
      </div>
    )
  }
}

const List = ({ item }) => {
  return (
    <div className='ul--specs'>
      {item.details && (
        <ul>
          {item.details.map((detail, i) => (
            <li key={i}>{detail}</li>
          ))}
        </ul>
      )}
      {item.detailsHtml && <BodyWrap body={item.detailsHtml} />}
    </div>
  )
}
