import React, { useState } from 'react'
import ButtonLink from 'components/ButtonLink'
import ImageWrap from 'components/ImageWrap'
import Inview from 'components/Inview'
import LinkWrap from 'components/LinkWrap'

const Component = ({ logos }) => {
  return (
    <div className='block-logo-grid bg--primary color--white'>
      <div className='container pt-20 md:pt-24 lg:pt-40 pb-20 md:pb-24 lg:pb-40'>
        <div className='text text-center'>
          <h1 className='h1'>品牌还未定?</h1>
          <p className='mt-8'>来看看星选加盟为您挑选的更优质的品牌们吧。</p>
        </div>
        <div className='buttons mt-12 flex justify-center'>
          <ButtonLink
            to='/merchants/'
            text='看看品牌'
            isYellow={true}
            addClass='map_brand'
          />
        </div>
        <Inview className='logos mt-24 grid gap-x-4 gap-y-4 grid-cols-3 sm:grid-cols-4 md:grid-cols-6 lg:grid-cols-8'>
          {logos.map((logo, i) => (
            <Logo key={i} i={i} logo={logo} />
          ))}
        </Inview>
      </div>
    </div>
  )
}

export default Component

const Logo = ({ logo, i }) => {
  if (logo.link) {
    return (
      <LinkWrap to={logo.link}>
        <LogoInner logo={logo} i={i} />
      </LinkWrap>
    )
  } else {
    return <LogoInner logo={logo} i={i} />
  }
}

const LogoInner = ({ logo, i }) => {
  return (
    <div
      className={`logo bg-white fade-in-up map-brand${i + 1}`}
      style={{ transitionDelay: `${i * 50}ms` }}
    >
      <ImageWrap image={logo.gatsbyImage} />
    </div>
  )
}
