import React from 'react'
import LinkWrap from 'components/LinkWrap'

const Component = ({
  to,
  text,
  isYellow = false,
  direction = 'right',
  addClass = '',
}) => {
  return (
    <LinkWrap
      to={to}
      className={`btn--contact ${isYellow ? '' : 'btn--link'} ${addClass}`}
    >
      <span className='default'>{text}</span>
      <span className='mask'>
        <span className='hover'>
          {direction === 'left' && (
            <span className='arrow arrow-bar is-left'></span>
          )}
          {text}
          {direction === 'right' && (
            <span className='arrow arrow-bar is-right'></span>
          )}
        </span>
      </span>
    </LinkWrap>
  )
}

export default Component
