import React from 'react'
import Inview from 'components/Inview'

class BlockCompare extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: 0,
      hover: null,
    }
    this.setSelected = this.setSelected.bind(this)
    this.setHover = this.setHover.bind(this)
  }

  setSelected(id) {
    this.setState({
      selected: id,
    })
  }
  setHover(id) {
    this.setState({
      hover: id,
    })
  }

  render() {
    const values = [
      [
        {
          stat: `0<span>元</span>`,
          title: '转让费',
          description:
            '星选闪电直租，7/24管家式贴心服务，您无需支付高额转让费，承担额外风险。',
        },
        {
          stat: `<span>低至</span>0<span>元</span>`,
          title: '装修改造费',
          description:
            '星选闪电为您提供的基础设备及公共设施，为您节省基建改造的费用，省钱又省心。',
        },
        {
          stat: `<span>最快</span>3<span>天开业</span>`,
          description:
            '星选闪电为您准备周全的场地，装修完备，协助证照办理、咨询等事宜。帮助您闪速开店。',
        },
        {
          stat: `<span>星选</span>直租`,
          description:
            '星选闪电直租，无任何中介费用，利用大数据选址工具，帮您高效匹配与您经营的品类适合的优质点位。',
        },
        {
          stat: `<span>星选</span>品牌`,
          description: `百余家严选合作品牌，更严格为您筛选靠谱的品牌。帮您核算单店盈利模型，通过“点位数据+品牌属性”的匹配原则，给您提供更安心的创业选择。`,
        },
      ],
      [
        {
          stat: `5-20<span>万</span>`,
          title: '转让费',
          description:
            '高昂的转让费延长了您的回本周期，也存在潜在遗留问题的风险。',
        },
        {
          stat: `10-20<span>万</span>`,
          title: '装修改造',
          description:
            '您的厨房装修、硬件改造、下水排风等基础搭建费用，大大延长了您的回本周期，且是您带不走的沉没成本。',
        },
        {
          stat: `60+<span>天开业</span>`,
          description:
            '选址、找楼宇、拿到您的营业执照与食品安全许可证、设计装修、开业……筹备时间可能半年到一年的时间——星选闪电帮您节省N倍的时间。',
        },
        {
          stat: `<span>中介</span>转租`,
          description:
            '为您省去中介费的同时，也节省您自己查询哪些地方生意好的时间，与中介比价过程，避免信息不对称造成的价格不透明',
        },
        {
          stat: `<span>盲选</span>品牌`,
          description: `品牌方与您之间的信息不对称，让您在筛选加盟品牌的过程中失去主动权，也附带更多潜在风险。`,
        },
      ],
    ]

    return (
      <section
        id='block-compare'
        className='BlockCompare relative BlockCompare__Section-sc-5bpfqc-0 dmcqSA bg--grey-2'
      >
        <div className='container pt-20 pb-20 md:pt-24 lg:pt-40 lg:pb-32'>
          <div className='grid grid-cols-4 md:grid-cols-9 lg:grid-cols-12'>
            <div className='col-span-2'>
              <div className='top-160'>
                <Inview>
                  <div className='eyebrow p5 line color--grey-4 mb-10 lg:mb-0 fade-in-up'>
                    找星选闪电
                  </div>
                </Inview>
              </div>
            </div>
            <div className='col-span-4 md:col-span-9 lg:col-span-10'>
              <div className='grid grid-cols-4 md:grid-cols-9 lg:grid-cols-10'>
                <div className='col-span-4 md:col-span-7 md:mb-16 lg:mb-32'>
                  <Inview>
                    <h2 className='h2 color--primary fade-in-up mb-2 md:mb-0'>
                      与自己开店对比
                    </h2>
                  </Inview>
                </div>
                <div className='col-span-4 pt-10 pb-10 sticky top-0 z-10 md:hidden bg--grey-2'>
                  <Inview>
                    <div
                      className={`primary btn--slider fade-in-up ${
                        this.state.selected === 0 ? 'delivery' : 'brick'
                      } hover-${this.state.hover}`}
                    >
                      <a
                        className='btn--slider-1'
                        onClick={() => this.setSelected(0)}
                        onMouseEnter={() => this.setHover(0)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        星选闪电
                        <span className='arrow arrow-bar is-right'></span>
                      </a>
                      <a
                        className='btn--slider-2'
                        onClick={() => this.setSelected(1)}
                        onMouseEnter={() => this.setHover(1)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        靠自己
                        <span className='arrow arrow-bar is-left'></span>
                      </a>
                    </div>
                  </Inview>
                </div>
                <div className='hidden md:block col-span-3 col-start-1 '>
                  <Inview>
                    <div
                      className={`delivery-fade btn--compare mt-4 pr-6 md:pr-10 lg:pr-20 fade-in-up 
                      selected-${this.state.selected}
                      hover-${this.state.hover}`}
                    >
                      {/* col-span-2 md:col-span-3 color--grey-5  */}

                      <div className='btn--compare--title color--grey-6'>
                        比较
                        <svg
                          width='16'
                          height='14'
                          fill='none'
                          className='inline'
                        >
                          <path
                            d='M.416 4.504l3.888 3.888 1.12-1.088L3.36 5.288h10.912V3.72H3.36l2.064-2.048L4.304.584.416 4.504zm9.6 8.4l1.12 1.072 3.888-3.872-3.888-3.92-1.12 1.104L12.08 9.32H1.168v1.568H12.08l-2.064 2.016z'
                            fill='#44433F'
                          ></path>
                        </svg>
                      </div>
                      <a
                        className={`btn--compare--item btn--compare--item--0 ${
                          this.state.selected === 0 ? 'selected' : ''
                        }`}
                        onClick={() => this.setSelected(0)}
                        onMouseEnter={() => this.setHover(0)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        <span className='off'>星选闪电</span>
                        <span className='mask'>
                          <span className='on'>
                            星选闪电
                            <span className='arrow arrow-bar is-right'></span>
                          </span>
                        </span>
                      </a>
                      <a
                        className={`btn--compare--item btn--compare--item--1 ${
                          this.state.selected === 1 ? 'selected' : ''
                        }`}
                        onClick={() => this.setSelected(1)}
                        onMouseEnter={() => this.setHover(1)}
                        onMouseLeave={() => this.setHover(null)}
                      >
                        <span className='off'>靠自己</span>
                        <span className='mask'>
                          <span className='on'>
                            靠自己
                            <span className='arrow arrow-bar is-right'></span>
                          </span>
                        </span>
                      </a>
                    </div>
                  </Inview>
                </div>
                {values[this.state.selected].map((value, i) => (
                  <Value
                    key={i}
                    i={i}
                    value={value}
                    selected={this.state.selected}
                  />
                ))}

                <div className='hidden md:block md:col-span-9 lg:col-span-8 mt-20 text-center hide-for-later'>
                  <a className='btn--rounded color--grey-6 btn--bg btn--bg--grey-3 inline-block'>
                    Full Comparison
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BlockCompare

const Value = ({ value, i, selected }) => {
  return (
    <div
      className={`value 
        value-${i + 1}
       col-span-2 md:col-span-3 color--grey-5 pr-6 md:pr-10 lg:pr-16 ${
         i > 1 ? 'mt-8 md:mt-16' : ''
       }`}
    >
      <Inview>
        <div className='fade-in-up'>
          <div
            className={`stat  mb-2 ${
              selected === 0 ? 'color--primary' : 'color--grey-5'
            }`}
            dangerouslySetInnerHTML={{ __html: value.stat }}
          />
          <hr className='mb-5' />
          <h3 className='h6 color--grey-6 mb-4'>{value.title}</h3>
          <p className='small'>{value.description}</p>
        </div>
      </Inview>
    </div>
  )
}
