import React, { useState, useEffect } from 'react'
import Inview from 'components/Inview'
import LocationsGrid from 'components/LocationsGrid'
import Select from 'react-select'

const Component = ({ cities, locations, addClassPrefix = '' }) => {
  const [activeSlug, setActiveSlug] = useState('shanghai')

  cities = cities.map((city) => {
    return {
      slug: city.uid,
      title: city.data.title.text,
      addClass: addClassPrefix
        ? `${addClassPrefix}${city.data.prefix.text}`
        : '',
    }
  })
  locations = locations.map((location, i) => {
    const city = cities.find((city) => city.slug === location.data.city.uid)
    return {
      city: city,
      slug: location.uid,
      title: location.data.title.text,
      excerpt: location.data.excerpt.text,
      gatsbyImage: location.data.image,
      addClassPrefix: city && city.addClass ? `${city.addClass}_s` : '',
    }
  })

  const updateActiveSlug = (newSlug) => {
    setActiveSlug(newSlug)
  }

  const handleSelect = (e) => {
    updateActiveSlug(e.value)
  }

  const selectOptions = cities.map((city) => {
    return {
      value: city.slug,
      label: city.title,
      addClass: city.addClass,
    }
  })

  const addClassToOptions = () => {
    const options = document.getElementsByClassName('react-select__option')
    Array.from(options).forEach((option, i) => {
      option.classList.add(selectOptions[i].addClass)
    })
  }

  useEffect(() => {
    addClassToOptions()
  })

  return (
    <section className='block-locations bg--grey-2 pt-20 md:pt-24 lg:pt-32'>
      <div className='container pb-20 md:pb-24 lg:pb-40 relative z-10'>
        <Inview className='text fade-in'>
          <h1 className='h1 smaller'>精选门店</h1>
          <p className='mt-8'>
            亲自来参观一下我们的实体厨房，见一下我们的团队，跟您未来的店长聊一聊。
            <br />
            了解具体门店的优惠政策。
          </p>
        </Inview>
        <div className='locations'>
          <Inview className='select fade-in'>
            <div className='mobile lg:hidden'>
              <div className='form--element'>
                <label className='form--element--label'>label</label>
                <Select
                  className='form--element--select'
                  classNamePrefix='react-select'
                  options={selectOptions}
                  onChange={handleSelect}
                  value={selectOptions.find(
                    (option) => option.value === activeSlug
                  )}
                  defaultValue={selectOptions[0]}
                  isSearchable={false}
                  // menuIsOpen={true}
                />
              </div>
            </div>
            <div className='desktop hidden lg:flex'>
              {cities.map((city, i) => (
                <a
                  key={i}
                  value={city.slug}
                  className={`
                    ${city.slug === activeSlug ? 'active' : ''}
                    ${city.addClass}
                  `}
                  onClick={() => updateActiveSlug(city.slug)}
                >
                  {city.title}
                </a>
              ))}
            </div>
          </Inview>
          <div className='results'>
            {cities.map((city, i) => (
              <div
                key={i}
                className={`city ${city.slug === activeSlug ? 'active' : ''}`}
              >
                <LocationsGrid
                  locations={locations}
                  filterCitySlug={city.slug}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

export default Component
