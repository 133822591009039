import React from 'react'
import Image from 'components/Image'
import ImagePng from 'components/ImagePng'
import ButtonContact from 'components/ButtonContact'
import Inview from 'components/Inview'

const Component = () => {
  return (
    <section className='block-hero-locations relative'>
      <Inview triggerOnce={true}>
        <Top />
        <div className='image--holder desktop hidden lg:block z-0 relative md:absolute right-0 top-0 fade-in'>
          <div className='image-bg'>
            <Image filename='image-hero-tw.jpg' />
          </div>
        </div>
      </Inview>
      <Inview>
        <div className='lg:hidden relative z-20 video-mobile-wrap'>
          <div className='image--holder mobile fade-in relative'>
            <div className='image-bg'>
              <Image filename='image-hero-tw.jpg' />
            </div>
          </div>
        </div>
      </Inview>
      <Inview>
        <Bottom />
      </Inview>
    </section>
  )
}

export default Component

const Top = () => {
  return (
    <div className={`container top z-10 relative flex items-center`}>
      <div className='sm:grid sm:grid-cols-4 md:grid-cols-8 lg:grid-cols-12'>
        {/* left text */}
        <div className='bg--grey-2 col-span-6 md:col-span-12 lg:col-span-8 pt-16 pb-20 md:pt-16 pr-8 md:pr-16 lg:pt-18 lg:pb-14 lg:pr-20 relative bg--left'>
          <Inview className='here'>
            {/* <div className='mb-8 lg:mb-12 w-450 fade-in-up stagger-400'>
              <p>店铺页面</p>
            </div> */}
            <h1 className='h1 smaller color--primary mb-10 md:mb-12 fade-in-up stagger-600'>
              <span>核心商圈，</span>
              <span>热门地段</span>
              <br />
              <span>更优选择，</span>
              <span>拎包入驻</span>
            </h1>
            <div className='lg:flex items-center fade-in-up stagger-1000'>
              <ButtonContact addClass='reserve_map' />
            </div>
          </Inview>
        </div>
      </div>
      <div className='scroll--indicator'>向下划动</div>
    </div>
  )
}
const Bottom = () => {
  return (
    <div className='container bottom z-10 relative'>
      <Inview>
        <div className='grid grid-cols-4 lg:grid-cols-12 mt-8 lg:mt-40 mb-10 lg:mb-14 color--white relative'>
          <div className='spacer hidden lg:block bg--primary col-span-1 col-start-4 pt-12 pb-10' />
          <div className='bg--primary col-span-2 pt-4 pb-4 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up'>
              <h3 className='h3 small color--accent'>7+</h3>
              <p>城市覆盖</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-4 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-200'>
              <h3 className='h3 small color--accent'>300+</h3>
              <p>门店数量</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-8 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-400'>
              <h3 className='h3 small color--accent'>500+</h3>
              <p>合作品牌</p>
            </div>
          </div>
          <div className='bg--primary col-span-2 pt-4 pb-8 lg:pt-12 lg:pb-10'>
            <div className='fade-in-up stagger-600'>
              <h3 className='h3 small color--accent'>4000+</h3>
              <p>商户数量</p>
            </div>
          </div>
        </div>
      </Inview>
    </div>
  )
}
